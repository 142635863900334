<template>
  <div class="absolute left-1/2 bottom-2 -translate-x-1/2 flex flex-col gap-1 z-50 max-w-full">
    <es-sheet v-for="[key, message] in Array.from(messages.entries()).reverse()" class="flex gap-3 group max-w-md relative overflow-hidden items-center shadow-xl" :key="message.id">
      <es-icon :icon="messageProps[message.type].icon" class="flex-shrink-0 h-6 w-6" :class="messageProps[message.type].color" />
      <div class="flex flex-col justify-center text-sm overflow-hidden gap-0.5">
        <span class="text-wrap text-ellipsis overflow-hidden"
              :class="{ 'font-semibold': message.description }">
          {{ message.title }}
        </span>
        <span v-if="message.description"
              class="text-disabled dark:text-dark-disabled text-xs text-wrap text-ellipsis overflow-hidden">
          {{ message.description }}
        </span>
      </div>
      <es-button class="opacity-0 flex-shrink-0 group-hover:opacity-100 transition-opacity" @click="messages.delete(message.id)">
        <es-icon :icon="iClose" />
      </es-button>
    </es-sheet>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {VisibleError, EsSheet, EsIcon, EsButton} from "@esigndoc/ui";
import {NotificationMessage} from "@/entities/notification-message.ts";
import {iAlert, iAlertCircle, iClose, iInformation} from "@/entities/icons.ts";

const messages = ref<Map<string, NotificationMessage>>(new Map())

const messageProps = {
  info: {
    color: 'text-accent-primary',
    icon: iInformation
  },
  warning: {
    color: 'text-warning',
    icon: iAlert
  },
  error: {
    color: 'text-error',
    icon: iAlertCircle
  }
} as const

onMounted(() => {
  window.onerror = async function (message, source, lineno, colno, error) {
    if (!(error instanceof VisibleError)) {
      return
    }
    const msg = new NotificationMessage({
      title: error.message,
      description: error.description,
      type: 'error' })

    messages.value.set(msg.id, msg)

    setTimeout(() => {
      messages.value.delete(msg.id)
    }, 70000)

    return
  };
})
</script>
