import {uuid} from "@/utils/uuid.ts";

export class NotificationMessage {
    id = uuid()
    type: 'error' | 'info' | 'warning'
    title: string
    description?: string

    constructor({type = 'info', title, description}: Omit<NotificationMessage, 'id'>) {
        this.type = type
        this.title = title
        this.description = description
    }
}
